import React from 'react'
import ErrorPage from 'next/error'
import Bugsnag from '../utils/Bugsnag'

export default class Page extends React.Component {
  static async getInitialProps (ctx) {
    if (ctx.err) {
      if (ctx.err instanceof Error) {
        Bugsnag.notify(ctx.err)
      } else if (ctx.err.message) {
        Bugsnag.notify(new Error(ctx.err.message))
      } else {
        Bugsnag.notify(new Error(JSON.stringify(ctx.err)))
      }
    }
    return ErrorPage.getInitialProps(ctx)
  }
  render () {
    return <ErrorPage statusCode={this.props.statusCode || '¯\\_(ツ)_/¯'} />
  }
}
